import { RichText } from 'prismic-reactjs'
import React from 'react'
import { ContactDetailsSection } from '../../types/pages/contact-page'

const ContactDetails = ({ data }: { data: ContactDetailsSection }) => (
  <section className="contact-details">
    <div className="contact-details__container container">
      <div className="contact-details__content">
        <span className="contact-details__subtitle subtitle">
          {data.contact_subtitle}
        </span>

        <h2 className="contact-details__title">{data.contact_title}</h2>

        <div className="contact-details__text">
          <RichText render={data.contact_text.richText} />
        </div>

        <div className="contact-details__blocks">
          {data.contact_blocks.map((block, index) => (
            <div className="contact-details__block" key={index}>
              <h3 className="contact-details__block-title">{block.title}</h3>

              {block.phone_number && (
                <a
                  href={`tel:${block.phone_number?.replace(/[-\ \(\)]/g, '')}`}
                  className="contact-details__phone-number"
                >
                  {block.phone_number}
                </a>
              )}

              {block.email_address && (
                <a
                  href={`mailto:${block.email_address}`}
                  className="contact-details__email-address"
                >
                  {block.email_address}
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
)

export default ContactDetails
