import { SiteConfig } from '@superrb/gatsby-addons/types'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { links, privacyLinks } from '../data/navigation'
import MainFooter, { InstagramSection } from '../types/main-footer'
import Instagram from './homepage/instagram'
import FooterLogo from './icons/footer--logo'
import IconFacebook from './icons/icon--facebook'
import IconInstagram from './icons/icon--instagram'
import IconTwitter from './icons/icon--twitter'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      footer: prismicMainFooter {
        ...MainFooter
      }
      config: prismicSiteConfig {
        ...SiteConfig
      }
    }
  `)

  const config: SiteConfig = data?.config
  if (!config) {
    return null
  }

  const footer: MainFooter = data?.footer

  return (
    <>
      <Instagram data={footer.data as InstagramSection} />
      <footer className="footer">
        <div className="footer__inner">
          <div className="footer__logo">
            <FooterLogo />
          </div>
          <div className="footer__title">
            <h3>
              <a href="tel:+44 1749 838 100">+44 1749 838 100</a>
            </h3>
            <h3>
              <a href="mailto:enquiries@eurilait.co.uk">
                enquiries@eurilait.co.uk
              </a>
            </h3>
          </div>
          <nav className="footer__nav">
            <ul className="footer__nav-list">
              {links.map(({ title, link }) => (
                <li className="footer__nav__list--item" key={link}>
                  <Link to={link}>
                    <p className="nav-item__text first">{title}</p>
                    <p className="nav-item__text second" aria-hidden="true">
                      {title}
                    </p>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div className="footer__socials">
            {config?.data?.instagram_username && (
              <Link to={config?.data?.instagram_username}>
                <IconInstagram />
              </Link>
            )}
            {config?.data?.facebook_username && (
              <Link to={config?.data?.facebook_username}>
                <IconFacebook />
              </Link>
            )}
            {config?.data?.twitter_username && (
              <Link to={config?.data?.twitter_username}>
                <IconTwitter />
              </Link>
            )}
          </div>
          <div className="footer__nav--privacy">
            <ul className="footer__nav-list">
              {privacyLinks.map(({ title, link }) => (
                <li className="footer__nav__list--item" key={link}>
                  <a href={link}>
                    <p className="nav-item__text first">{title}</p>
                    <p className="nav-item__text second" aria-hidden="true">
                      {title}
                    </p>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer__credits">
            <small>
              Paysan Breton 2022. All rights reserved. Website by{' '}
              <a
                href={'https://www.superrb.com'}
                target="_blank"
                rel="nofollow noopener"
                title="Website by Superrb"
              >
                Superrb.
              </a>
            </small>
          </div>
        </div>
        <div className="separator"></div>
      </footer>
    </>
  )
}

export default Footer
