import React from 'react'
import { graphql } from 'gatsby'
import { LegalPage as LegalPageType } from '../types/pages/legal-page'
import { RichText } from 'prismic-reactjs'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const LegalPage = ({ data }) => {
  const page: LegalPageType = data.page

  return (
    <div className="legal-page">
      <div className="legal-page__container container">
        <h1 className="legal-page__title">{page.data.page_title}</h1>
        <div className="legal-page__text">
          <RichText render={page.data.header_text.richText} />
        </div>
        <div className="legal-page__content">
          <RichText render={page.data.main_content.richText} />
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query LegalPageQuery($id: String) {
    page: prismicLegalPage(id: { eq: $id }) {
      ...LegalPage
    }
  }
`

export default withPrismicPreview(LegalPage)
