module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"paysanbreton","accessToken":"MC5ZdFVrd2hBQUFDTUFEQWFw.77-9V--_vXsx77-9QiAkN--_vV0w77-9VWzvv71977-9HjTvv70477-9JlhnDUfvv71fAg","promptForAccessToken":true,"apiEndpoint":"https://paysanbreton.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-TLWX4C7","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"cookieName":"gatsby-gdpr-facebook-pixel"},"tikTokPixel":{"cookieName":"gatsby-gdpr-tiktok-pixel"},"hotjar":{"cookieName":"gatsby-gdpr-hotjar"},"linkedin":{"cookieName":"gatsby-gdpr-linked-in"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#E20714","theme_color":"#E20714","display":"minimal-ui","icon":"/opt/buildhome/repo/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7e9b2b17924bf8456a94057f7f24e0f8"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
