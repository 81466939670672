import { Image } from '@superrb/gatsby-addons/components'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { VideoSection } from '../../types/pages/homepage'
import Button from '../button'

interface Props {
  data: VideoSection
}

const Video = ({ data }: Props) => {
  const container = useRef<HTMLElement>(null)
  const [playing, setPlaying] = useState<boolean>(false)

  const publicUrl = data.video_url.url
  const [videoUrl, setVideoUrl] = useState(publicUrl)
  const ref = useRef(null)
  const { isInViewport, setRef } = useIsInViewport(false)

  const [minScale, setMinScale] = useState<number>(0)
  const [maxScale, setMaxScale] = useState<number>(1)
  const [scale, setScale] = useState<number>(0)

  const handleScroll = useCallback(() => {
    requestAnimationFrame(() => {
      const box = ref.current.getBoundingClientRect()
      const percentScrolled = (box.bottom - window.innerHeight) / window.innerHeight
      const scaledSize = maxScale - ((maxScale - minScale) * percentScrolled)

      setScale(Math.min(maxScale, Math.max(minScale, scaledSize)))
    })
  }, [minScale, maxScale, setScale])

  const handleResize = useCallback(() => {
    if (container.current) {
      const minWidth = parseInt(container.current.clientWidth, 10) - (parseInt(window.getComputedStyle(container.current, null)?.paddingLeft, 10) * 2)
      setMinScale(minWidth / window.innerWidth)
    }
  }, [container.current, setMinScale, setMaxScale, handleScroll])

  useLayoutEffect(() => {
    handleResize()
    document.addEventListener('DOMContentLoaded', handleResize)

    return () => {
      document.removeEventListener('DOMContentLoaded', handleResize)
    }
  }, [])

  useEffect(() => {
    handleScroll()
  }, [container.current, ref.current, isInViewport, maxScale, minScale])

  useEffect(() => {
    if (playing === true && container.current) {
      container.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })

      setTimeout(() => {
        document.body.style.overflow = 'hidden'
      }, 500)
    } else {
      document.body.style.overflow = 'auto'
    }

    return(() => {
      document.body.style.overflow = 'hidden'
    })
  }, [playing])

  useEffect(() => {
    if (ref.current && isInViewport) {
      window.addEventListener('scroll', handleScroll, { passive: true })
      window.addEventListener('resize', handleResize, { passive: true })
    } else {
      window.removeEventListener('scroll', handleScroll, { passive: true })
      window.removeEventListener('resize', handleResize, { passive: true })
    }

    return () => {
      window.removeEventListener('scroll', handleScroll, { passive: true })
      window.removeEventListener('resize', handleResize, { passive: true })
    }
  }, [ref, isInViewport])

  useEffect(() => {
    if (!publicUrl) {
      return
    }

    setVideoUrl(publicUrl)
  }, [publicUrl])

  return (
    <section
      className={`video ${playing && 'video--playing'}`}
      aria-label={data.video_title}
      ref={setRef}
    >
      <div className="video__container container" ref={container}>
        <div
          className="video__wrapper"
          ref={ref}
          style={{ transform: `translate3d(-50%, 0, 0) scale(${scale})` }}
        >
          <Image
            className="video__image"
            image={data.video_background_image}
            style={{ position: 'absolute' }}
          />

          <ReactPlayer
            className="video__video"
            id="video"
            url={videoUrl}
            playsinline={true}
            width="100%"
            height="100%"
            playing={playing}
            controls={true}
            onPause={() => setPlaying(false)}
          />

          <div
            className="video__content"
            onClick={() => setPlaying(true)}
            aria-controls="video"
          >
            <h2 className="video__title">{data.video_title}</h2>
            <Button
              className="video__play"
              buttonStyle="play"
              label={data.video_button_label}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Video
