import { Image } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { animator } from '@superrb/gatsby-addons/utils'
import { RichText } from 'prismic-reactjs'
import React from 'react'
import { AwardsSection } from '../../types/pages/homepage'

interface Props {
  data: AwardsSection
}

const Awards = ({ data }: Props) => {
  const isMobile = useIsMobile(true)

  return (
    <section className="awards" aria-label={data.awards_title}>
      <div className="awards__container container">
        <div className="awards__content reveal-text" ref={animator}>
          <span className="awards__subtitle subtitle">
            {data.awards_subtitle}
          </span>
          <h2 className="awards__title">{data.awards_title}</h2>
          <div className="awards__text">
            <RichText render={data.awards_text.richText} />
          </div>
        </div>

        <div className="awards__wrapper" ref={animator}>
          <ul className="awards__list">
            {data.awards_logos.map((logo, id) => (
              <li className="awards__item" key={id}>
                <Image className="awards__logo" image={logo.logo} />
              </li>
            ))}
          </ul>

          {isMobile &&
            [1, 2].map((_, i) => (
              <ul className="awards__list" key={i} aria-hidden={true}>
                {data.awards_logos.map((logo, id) => (
                  <li className="awards__item" key={`${i}-${id}`}>
                    <Image className="awards__logo" image={logo.logo} />
                  </li>
                ))}
              </ul>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Awards
