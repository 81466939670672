export const links = [
  {
    title: 'Our Products',
    link: '/#featured-products',
  },
  {
    title: 'About Us',
    link: '/#farms',
  },
  {
    title: 'Where to Buy',
    link: '/#where-to-buy',
  },
  {
    title: 'Contact Us',
    link: '/contact-us/',
  },
]

export const privacyLinks = [
  {
    title: 'Privacy Policy',
    link: '/legal/privacy-policy/',
  },
  {
    title: 'Cookies Policy',
    link: '/legal/cookies-policy/',
  },
  {
    title: 'Terms & Conditions',
    link: '/legal/terms-conditions/',
  },
]
