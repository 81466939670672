import React from 'react'

const IconFacebook = () => {
  return (
    <svg
      width="14"
      height="27"
      viewBox="0 0 14 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.06756 26.5872V14.623H12.7007L13.2936 9.30552H9.06756V6.71593C9.06756 5.34668 9.10345 3.98808 11.0164 3.98808H12.9546V0.18611C12.9546 0.128948 11.2889 0 9.60462 0C6.08847 0 3.88572 2.20275 3.88572 6.24799V9.30552H0V14.623H3.88572V26.5872H9.06756Z"
        fill="#13372C"
      />
    </svg>
  )
}

export default IconFacebook
