import { Image } from '@superrb/gatsby-addons/components'
import { animator } from '@superrb/gatsby-addons/utils'
import { RichText } from 'prismic-reactjs'
import React, { useCallback, useState } from 'react'
import { FarmsSection, FarmsSectionSlide } from '../../types/pages/homepage'
import Button from '../button'

interface Props {
  data: FarmsSection
}

const Slide = ({
  slide,
  selected = false,
}: {
  slide: FarmsSectionSlide
  selected?: boolean
}) => (
  <li className="farms__slide" aria-selected={selected}>
    <Image
      image={slide.image}
      className="farms__slide-image"
      sizes="(min-width: 63.75em) 40vw, 90vw"
    />
    <span className="farms__slide-fact">
      <b>Fun Fact</b>: {slide.fact}
    </span>
  </li>
)

const Pagination = ({
  controls,
  callbacks: { increment, decrement, setSelectedSlide },
  current = 0,
  count = 0,
}: {
  controls: string
  callbacks: {
    increment: Function
    decrement: Function
    setSelectedSlide: Function
  }
  current: number
  count: number
}) => (
  <nav className="farms__pagination">
    <Button
      buttonStyle="arrow prev"
      aria-controls={controls}
      onClick={decrement}
    />

    {Array.from({ length: count }, (_, i) => (
      <span
        className="farms__pagination-item"
        aria-selected={i === current}
        key={i}
      ></span>
    ))}

    <Button
      buttonStyle="arrow next"
      aria-controls={controls}
      onClick={increment}
    />
  </nav>
)

const Farms = ({ data }) => {
  const [slides, setSlides] = useState<FarmsSectionSlide[]>(
    data.farms_slides.slice(0, 3),
  )
  const [selectedSlide, setSelectedSlide] = useState<number>(0)

  const increment = useCallback(
    (event: MouseEvent) => {
      setSelectedSlide((current) => {
        const next = current + 1
        return next < slides.length ? next : 0
      })
    },
    [setSelectedSlide],
  )

  const decrement = useCallback(
    (event: MouseEvent) => {
      setSelectedSlide((current) => {
        const next = current - 1
        return next >= 0 ? next : slides.length - 1
      })
    },
    [setSelectedSlide],
  )

  return (
    <section className="farms" id="farms" aria-label={data.farms_title}>
      <div className="farms__container container">
        <div
          id="farms-slideshow"
          className="farms__slideshow"
          aria-live="polite"
          data-selected={selectedSlide}
        >
          <ul className="farms__slides">
            {slides.map((slide, i) => (
              <Slide
                slide={slide}
                selected={data.farms_slides.indexOf(slide) === selectedSlide}
                key={i}
              />
            ))}
          </ul>

          <Pagination
            controls="farms-slideshow"
            callbacks={{ increment, decrement, setSelectedSlide }}
            current={selectedSlide}
            count={slides.length}
            onClick={setSelectedSlide}
          />
        </div>

        <div className="farms__content reveal-text" ref={animator}>
          <span className="farms__subtitle subtitle">
            {data.farms_subtitle}
          </span>

          <h2 className="farms__title">{data.farms_title}</h2>

          <div className="farms__text">
            <RichText render={data.farms_text.richText} />
          </div>

          <ul className="farms__bullets">
            {data.farms_bullet_points.map((bullet, i) => (
              <li className="farms__bullet" key={i}>
                {bullet.text}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Farms
