import { Image } from '@superrb/gatsby-addons/components'
import { animator } from '@superrb/gatsby-addons/utils'
import { RichText } from 'prismic-reactjs'
import React from 'react'
import {
  FeaturedProductsSection,
  Product as ProductType
} from '../../types/pages/homepage'
import IconGarlic from '../icons/icon--garlic'
import IconSalt from '../icons/icon--salt'

interface Props {
  data: FeaturedProductsSection
}

const Product = ({ product, index }: { product: ProductType, index: Number }) => (
  <li className="featured-products__item">
    <div className="featured-products__item-images">
      <Image
        className="featured-products__background"
        image={product.background}
        sizes="(min-width: 63.75em) 30vw, 100vw"
      />
      <Image
        className="featured-products__thumbnail"
        image={product.thumbnail}
        sizes="(min-width: 63.75em) 15vw, 50vw"
      />
      {index === 0 && (
        <IconSalt/>
      )}
      {index === 1 && (
        <IconGarlic/>
      )}
    </div>

    <div className="featured-products__item-content reveal-text" ref={animator}>
      <h3 className="featured-products__item-name">{product.name}</h3>
      <div className="featured-products__item-description">
        <RichText render={product.description.richText} />
      </div>
    </div>
  </li>
)

const FeaturedProducts = ({ data }: Props) => (
  <section
    className="featured-products"
    id="featured-products"
    aria-label={data.featured_products_title}
  >
    <div className="featured-products__container container">
      <div className="featured-products__content reveal-text" ref={animator}>
        <span className="featured-products__subtitle subtitle">
          {data.featured_products_subtitle}
        </span>

        <h2 className="featured-products__title">
          {data.featured_products_title}
        </h2>

        <div className="featured-products__text">
          <RichText render={data.featured_products_text.richText} />
        </div>
      </div>

      <ul className="featured-products__list">
        {data.featured_products_products.map((product, i) => (
          <Product product={product} key={i} index={i}/>
        ))}
      </ul>
    </div>
  </section>
)

export default FeaturedProducts
