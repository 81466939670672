import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  DetailedHTMLProps
} from 'react'
import ButtonArrow from './icons/button--arrow'
import ButtonPlay from './icons/button--play'

type ButtonStyle =
  | 'white'
  | 'black'
  | 'arrow prev'
  | 'arrow next'
  | 'green'
  | 'white'
  | 'play'

interface Props {
  buttonStyle?: ButtonStyle
  label: string
  className?: string
  href?: string
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
}

const Button = ({
  href,
  label,
  buttonStyle = 'green',
  className = '',
  disabled,
  ...props
}: Props) => {
  const labelHTML = (
    <>
      {buttonStyle === 'arrow prev' || buttonStyle === 'arrow next' ? (
        <>
          <ButtonArrow />
          <ButtonArrow />
        </>
      ) : null}
      {buttonStyle === 'play' ? (
        <span className="button__icon">
          <ButtonPlay />
        </span>
      ) : null}

      <span className="button__label" data-label={label}>{label}</span>
    </>
  )

  if (href) {
    return (
      <a
        className={`button button--${buttonStyle} ${className}`}
        href={href}
        {...(props as DetailedHTMLProps<
          AnchorHTMLAttributes<HTMLAnchorElement>,
          HTMLAnchorElement
        >)}
      >
        {labelHTML}
      </a>
    )
  }

  return (
    <button
      disabled={disabled}
      className={`button button--${buttonStyle} ${className}`}
      {...(props as DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >)}
    >
      {labelHTML}
    </button>
  )
}

export default Button
