import { Image } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { animator } from '@superrb/gatsby-addons/utils'
import React from 'react'
import 'swiper/css'
import { InstagramSection } from '../../types/main-footer'

interface Props {
  data: InstagramSection
}

const Instagram = ({ data }: Props) => {
  const isMobile = useIsMobile()

  return (
    <section className="instagram" aria-label={data?.instagram_title}>
      <div className="instagram__container">
        <div className="instagram__header reveal-text" ref={animator}>
          {data?.instagram_handle && data?.instagram_url && (
            <h5 className="instagram__handle">
              <a href={data?.instagram_url}  target='_blank' rel='noopener'>
                {data?.instagram_handle}
              </a>
            </h5>
          )}
          {data?.instagram_handle && !data?.instagram_url && (
            <h5 className="instagram__handle">{data?.instagram_handle}</h5>
          )}
          {data?.instagram_title && (
            <h2 className="instagram__title">{data?.instagram_title}</h2>
          )}
        </div>
        <div className="instagram__images" ref={animator}>
          {[0, 1, 2].map((_, i) => (
            <ul className="instagram__images-list" key={i}>
              {data?.instagram_images?.map((image, index) => (
                <li
                  className="instagram__image"
                  key={`${i}-${index}`}
                  aria-hidden={i !== 1}
                >
                  <a href={data?.instagram_url} target='_blank' rel='noopener'>
                    <Image
                      image={image.image}
                      className="instagram__swiper--image"
                      sizes="(min-width: 63.75em) 20vw, 100vw"
                    />
                  </a>
                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Instagram
