import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import scrollToHash from '../utils/scroll-to-hash'
import { BackToTop } from './back-to-top'
import { CookieBanner } from './cookie-banner'
import Footer from './footer'
import Header from './header'
import { SkipTo } from './skip-to'

export const Layout = ({ children }) => {
  const handleHashOnLoad = useCallback(() => {
    if (window.location.hash) {
      scrollToHash(window.location.hash.slice(1))
    }
  }, [])

  useLayoutEffect(() => {
    handleHashOnLoad()
  }, [])

  // useEffect(() => {
    // document.documentElement.classList.add('document-ready')
  // }, [])

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/rdq0shx.css" />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
      </Helmet>
      <SkipTo />
      <Header />
      <main id="content">{children}</main>
      <CookieBanner />
      <Footer />
      <BackToTop />
    </>
  )
}
