import { Image } from '@superrb/gatsby-addons/components'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
import { animator } from '@superrb/gatsby-addons/utils'
import { RichText } from 'prismic-reactjs'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FarmToForkSection } from '../../types/pages/homepage'
import Button from '../button'

interface Props {
  data: FarmToForkSection
}

const FarmToFork = ({ data }: Props) => {
  const container = useRef<HTMLElement>(null)
  const { isInViewport, setRef } = useIsInViewport(false)
  const [pos, setPos] = useState<number>(0)
  const handleScroll = useCallback(
    (event) => {
      requestAnimationFrame(() => {
        setPos(
          (container.current.getBoundingClientRect().top /
            window.innerHeight /
            8) *
            100,
        )
      })
    },
    [setPos],
  )

  useEffect(() => {
    if (container.current && isInViewport) {
      window.addEventListener('scroll', handleScroll)
    } else {
      window.removeEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [container, isInViewport, handleScroll])

  return (
    <section
      className="farm-to-fork"
      id="farm-to-fork"
      aria-label={data.farm_to_fork_title}
      ref={container}
    >
      <div className="farm-to-fork__container container" ref={setRef}>
        <div className="farm-to-fork__content reveal-text" ref={animator}>
          <span className="farm-to-fork__subtitle subtitle">
            {data.farm_to_fork_subtitle}
          </span>

          <h2 className="farm-to-fork__title">{data.farm_to_fork_title}</h2>

          <div className="farm-to-fork__text">
            <RichText render={data.farm_to_fork_text.richText} />
          </div>

          <Button href="#farms" label={data.farm_to_fork_button_label} />
        </div>

        <div className="farm-to-fork__images">
          <Image
            className="farm-to-fork__image farm-to-fork__image--one"
            image={data.farm_to_fork_image_one}
            imgStyle={{ transform: `translateY(${pos}%)` }}
            sizes="(min-width: 63.75em) 55vw, 30vw"
          />
          <Image
            className="farm-to-fork__image farm-to-fork__image--two"
            image={data.farm_to_fork_image_two}
            imgStyle={{ transform: `translateY(${pos * 3}%)` }}
            sizes="(min-width: 63.75em) 45vw, 25vw"
          />
        </div>
      </div>
    </section>
  )
}

export default FarmToFork
