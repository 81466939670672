import { animator } from '@superrb/gatsby-addons/utils'
import React from 'react'
import { TickerTapeSection } from '../../types/pages/homepage'

interface Props {
  data: TickerTapeSection
}

const TickerTape = ({ data }: Props) => {
  return (
    <section className="ticker-tape" ref={animator}>
      {[1, 2].map((_, i) => (
        <ul className="ticker-tape__items" key={i}>
          {data.ticker_tape_items.map((item, j) => (
            <li className="ticker-tape__item" key={j}>
              <span className="ticker-tape__item-text">{item.text}</span>
            </li>
          ))}
        </ul>
      ))}
    </section>
  )
}

export default TickerTape
