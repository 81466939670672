import { Image } from '@superrb/gatsby-addons/components'
import { useIsInViewport, useIsMobile } from '@superrb/gatsby-addons/hooks'
import { animator } from '@superrb/gatsby-addons/utils'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Hero as HeroType } from '../types/components/hero'
import Button from './button'

interface Props {
  data: HeroType
}

const Hero = ({ data }: Props) => {
  const isMobile = useIsMobile(false)
  const { isInViewport, setRef } = useIsInViewport(true)
  const container = useRef<HTMLElement>(null)
  const [pos, setPos] = useState<number>(0)
  const handleScroll = useCallback(
    (event) => {
      requestAnimationFrame(() => {
        setPos((window.scrollY / window.innerHeight / 2.5) * 100)
      })
    },
    [setPos],
  )

  useEffect(() => {
    if (container.current && isInViewport) {
      window.addEventListener('scroll', handleScroll)
    } else {
      window.removeEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [container, isInViewport, handleScroll])

  return (
    <header
      className="hero"
      id="hero"
      aria-label={data.page_title}
      ref={setRef}
    >

      {data?.hero_image?.fluid?.src && (
        <Image
          className="hero__image hero__image--desktop"
          image={data?.hero_image}
          sizes="100vw"
          style={{ position: 'absolute' }}
        />
      )}

      {isMobile && data?.hero_image_mobile?.fluid?.src && (
        <Image
          className="hero__image hero__image--mobile"
          image={data?.hero_image_mobile}
          sizes="100vw"
          style={{ position: 'absolute' }}
        />
      )}

      <div
        className="hero__content-wrapper"
        style={{ transform: `translateY(${pos}vh)` }}
        ref={container}
      >
        <div className="hero__container container" ref={animator}>
          <h1 className="hero__title">{data.page_title}</h1>

          {data.hero_button_label && (
            <Button
              className="hero__button"
              label={data.hero_button_label}
              href={data.hero_button_link?.url}
              buttonStyle="white"
            />
          )}
        </div>
      </div>
    </header>
  )
}

export default Hero
