import React from 'react'

const IconTwitter = () => {
  return (
    <svg
      width="27"
      height="22"
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.4915 22C18.6795 22 24.2528 13.5361 24.2528 6.19539C24.2528 5.95455 24.2528 5.71488 24.237 5.47752C25.317 4.69531 26.2527 3.72094 27 2.60027C25.9872 3.05042 24.9156 3.34496 23.8196 3.47445C24.9681 2.78966 25.8342 1.69697 26.2552 0.401519C25.1638 1.05048 23.9743 1.50644 22.7362 1.75041C20.6392 -0.485392 17.1304 -0.59423 14.8984 1.50958C14.1951 2.16639 13.6756 3.00508 13.3939 3.93846C13.1122 4.87184 13.0785 5.86584 13.2964 6.81715C8.8425 6.59369 4.69125 4.48409 1.87875 1.01518C0.4095 3.55318 1.161 6.79978 3.5955 8.42887C2.71565 8.40364 1.85393 8.16565 1.08 7.73416V7.80479C1.08113 10.4493 2.94075 12.7256 5.52375 13.2501C4.70776 13.4732 3.8536 13.5052 3.024 13.3439C3.7485 15.6075 5.82975 17.1579 8.199 17.203C5.86106 19.0389 2.91989 19.8646 0 19.5048C2.54729 21.1388 5.48999 22.0019 8.4915 21.9954"
        fill="#13372C"
      />
    </svg>
  )
}

export default IconTwitter
