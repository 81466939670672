import React from 'react'

const ButtonPlay = () => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0L9 4.5L0 9V0Z" fill="#13372C" />
    </svg>
  )
}

export default ButtonPlay
