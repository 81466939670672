import { Image } from '@superrb/gatsby-addons/components'
import { animator } from '@superrb/gatsby-addons/utils'
import { RichText } from 'prismic-reactjs'
import React from 'react'
import {
  WhereToBuyRetailer,
  WhereToBuySection,
} from '../../types/pages/homepage'
import Button from '../button'

interface Props {
  data: WhereToBuySection
}

const Retailer = ({ retailer }: { retailer: WhereToBuyRetailer }) => {
  let linkContent = (
    <>
      <Image className="where-to-buy__icon" image={retailer.logo} />
      <span className="where-to-buy__retailer-name">{retailer.name}</span>
    </>
  )

  if (retailer.link?.url) {
    linkContent = (
      <a
        className="where-to-buy__link"
        href={retailer.link.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkContent}
      </a>
    )
  }
  return <li className="where-to-buy__item">{linkContent}</li>
}

const WhereToBuy = ({ data }: Props) => (
  <section
    id="where-to-buy"
    className="where-to-buy"
    aria-label={data.where_to_buy_title}
  >
    <div className="where-to-buy__container container">
      <div className="where-to-buy__content-wrapper">
        <div className="where-to-buy__content reveal-text" ref={animator}>
          <span className="where-to-buy__subtitle subtitle">
            {data.where_to_buy_subtitle}
          </span>
          <h2 className="where-to-buy__title">{data.where_to_buy_title}</h2>
          <div className="where-to-buy__text">
            <RichText render={data.where_to_buy_text.richText} />
          </div>

          <Button
            href={data.where_to_buy_button_link.url}
            label={data.where_to_buy_button_label}
          />
        </div>

        <div className="where-to-buy__retailers" ref={animator}>
          {[0, 1, 2].map((_, i) => (
            <ul className="where-to-buy__list" key={i} aria-hidden={i !== 1}>
              {data.where_to_buy_retailers.map((retailer, id) => (
                <Retailer retailer={retailer} key={`${i}-${id}`} />
              ))}
            </ul>
          ))}
        </div>
      </div>
      <div className="where-to-buy__image-wrapper">
        <Image
          className="where-to-buy__image"
          image={data.where_to_buy_image}
          sizes="(min-width: 63.75em) 50vw, 100vw"
          style={{ position: 'absolute' }}
        />
      </div>
    </div>
  </section>
)

export default WhereToBuy
