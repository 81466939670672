import { useAsync } from '@superrb/gatsby-addons/hooks'
import { Field, Form, Formik } from 'formik'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import React, { useCallback, useRef } from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import * as Yup from 'yup'
import { ContactFormSection } from '../../types/pages/contact-page'
import { linkResolver } from '../../utils/linkResolver'
import Button from '../button'

const NATURE_OF_ENQUIRY_OPTIONS = ['General Enquiries']

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter your name'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),
  phone: Yup.string(),
  natureOfEnquiry: Yup.string()
    .required('Please select the nature of your enquiry')
    .matches(new RegExp(NATURE_OF_ENQUIRY_OPTIONS.join('|'))),
  recaptchaToken: Yup.string().required('Recaptcha failed'),
  message: Yup.string().required('Please enter your message'),
})

const SuccessMessage = ({ data }: { data: ContactFormSection }) => {
  return (
    <div className="contact-form__success-message">
      <h3 className="contact-form__success-message-title">
        {data.form_success_title}
      </h3>
      <div className="contact-form__success-message-content">
        <RichText
          render={data.form_success_message.richText}
          linkResolver={linkResolver}
        />
      </div>
    </div>
  )
}

const ContactForm = ({ data }: { data: ContactFormSection }) => {
  const contactFormRef = useRef<HTMLElement>(null)
  const recaptchaField = useRef<HTMLElement>(null)

  const handleSubmit = useCallback(async (values) => {
    const response = await fetch(
      process.env.GATSBY_WORKER_CONTACT_HANDLER,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      },
    )

    if (!response.ok) {
      if (contactFormRef.current) {
        contactFormRef.current.scrollIntoView({ behavior: 'smooth' })
      }
      throw new Error('Sorry, an error occurred')
    }

    return true
  }, [])

  const { execute, status, error } = useAsync(handleSubmit, false, [])

  return (
    <section className="contact-form" ref={contactFormRef}>
      <div className="contact-form__container container">
        <div className="contact-form__wrapper">
          {status === 'success' ? (
            <SuccessMessage data={data} />
          ) : (
            <Formik
              initialValues={{
                name: '',
                email: '',
                phone: '',
                natureOfEnquiry: '',
                recaptchaToken: '',
                message: '',
              }}
              validationSchema={ValidationSchema}
              onSubmit={execute}
            >
              {({ errors, touched, values, setFieldValue }) => {
                return (
                  <Form className="form contact-form__form">
                    <h3 className="contact-form__title">{data.form_title}</h3>
                    {error && (
                      <span className="form__global-error">
                        {data.form_error_message}
                      </span>
                    )}
                    {errors.recaptchaToken && (
                      <span className="form__global-error">
                        {errors.recaptchaToken}
                      </span>
                    )}

                    <div
                      className={`form__group ${
                        touched.name ? 'form__group--filled' : ''
                      } ${
                        errors.name && touched.name ? 'form__group--error' : ''
                      }`}
                    >
                      <Field name="name" />
                      <label className="form__label" htmlFor="name">
                        <span className="form__label-text">Name*</span>
                      </label>
                      {errors.name && touched.name && (
                        <span className="form__error">{errors.name}</span>
                      )}
                    </div>

                    <div
                      className={`form__group ${
                        touched.email ? 'form__group--filled' : ''
                      } ${
                        errors.email && touched.email
                          ? 'form__group--error'
                          : ''
                      }`}
                    >
                      <Field name="email" type="email" />
                      <label className="form__label" htmlFor="email">
                        <span className="form__label-text">Email address*</span>
                      </label>
                      {errors.email && touched.email && (
                        <span className="form__error">{errors.email}</span>
                      )}
                    </div>

                    <div
                      className={`form__group ${
                        touched.phone ? 'form__group--filled' : ''
                      } ${
                        errors.phone && touched.phone
                          ? 'form__group--error'
                          : ''
                      }`}
                    >
                      <Field name="phone" type="tel" />
                      <label className="form__label" htmlFor="phone">
                        <span className="form__label-text">Phone number</span>
                      </label>
                      {errors.phone && touched.phone && (
                        <span className="form__error">{errors.phone}</span>
                      )}
                    </div>

                    <div
                      className={`form__group ${
                        touched.natureOfEnquiry ? 'form__group--filled' : ''
                      } ${
                        errors.natureOfEnquiry && touched.natureOfEnquiry
                          ? 'form__group--error'
                          : ''
                      }`}
                    >
                      <Field name="natureOfEnquiry" as="select">
                        <option></option>
                        {NATURE_OF_ENQUIRY_OPTIONS.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Field>
                      <label className="form__label" htmlFor="natureOfEnquiry">
                        <span className="form__label-text">
                          Nature of Enquiry*
                        </span>
                      </label>
                      {errors.natureOfEnquiry && touched.natureOfEnquiry && (
                        <span className="form__error">
                          {errors.natureOfEnquiry}
                        </span>
                      )}
                    </div>

                    <div
                      className={`form__group form__group--textarea ${
                        touched.message ? 'form__group--filled' : ''
                      } ${
                        errors.message && touched.message
                          ? 'form__group--error'
                          : ''
                      }`}
                    >
                      <Field name="message" as="textarea" />
                      <label className="form__label" htmlFor="message">
                        <span className="form__label-text">Message*</span>
                      </label>
                      {errors.message && touched.message && (
                        <span className="form__error">{errors.message}</span>
                      )}
                    </div>

                    <Field
                      name="recaptchaToken"
                      ref={recaptchaField}
                      type="hidden"
                    />

                    {!values.recaptchaToken && (
                      <GoogleReCaptcha
                        onVerify={(token) =>
                          setFieldValue('recaptchaToken', token)
                        }
                      />
                    )}

                    {status !== 'pending' && (
                      <Button type="submit" label="Send Message" />
                    )}

                    <span className="form__recaptcha">
                      This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                    </span>
                  </Form>
                )
              }}
            </Formik>
          )}
        </div>
      </div>
    </section>
  )
}

export default ContactForm
