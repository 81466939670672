import React from 'react'

const ButtonArrow = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5938 7.86667C13.1354 7.33333 13.1354 6.53333 12.5938 6L6.90625 0.4C6.36458 -0.133333 5.55208 -0.133333 5.01042 0.4C4.46875 0.933334 4.46875 1.73333 5.01042 2.26667L8.39583 5.6L1.35417 5.6C0.541666 5.6 -2.65949e-07 6.13333 -3.35887e-07 6.93333C-4.05825e-07 7.73333 0.541666 8.26667 1.35417 8.26667L8.39583 8.26667L4.875 11.7333C4.33333 12.2667 4.33333 13.0667 4.875 13.6C5.41667 14.1333 6.22917 14.1333 6.77083 13.6L12.5938 7.86667Z"
        fill="#13372C"
      />
    </svg>
  )
}

export default ButtonArrow
