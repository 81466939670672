import { Link } from 'gatsby'
import Cookies from 'js-cookie'
import React, { useState } from 'react'

export const CookieBanner = () => {
  // Have we already accepted cookies?
  const accepted = Cookies.get('gatsby-accepted-cookies') || false

  const [animate, setAnimate] = useState(false)
  const [showCookieBanner, setShowCookieBanner] = useState(!accepted)

  const close = () => {
    setAnimate(true)
    window.setTimeout(() => {
      setShowCookieBanner(false)
    }, 500)
  }

  const accept = () => {
    Cookies.set('gatsby-gdpr-google-analytics', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-google-tagmanager', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-facebook-pixel', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-tiktok-pixel', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-hotjar', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-linked-in', 'true', { expires: 30 })
    Cookies.set('gatsby-accepted-cookies', 'true', { expires: 30 })
    close()
  }

  const reject = () => {
    close()
  }

  return (
    <>
      {showCookieBanner && (
        <div
          className={`cookie-banner ${
            animate ? ' cookie-banner--hide' : ''
          } `}
        >
          <div className="cookie-banner__container container">
            <div className="cookie-banner__message">
              We use cookies to ensure that we give you the best experience on
              our website.
              <Link to="/legal/cookies-policy"> Read cookies policies. </Link>
            </div>
            <div className="cookie-banner__buttons">
              <button
                onClick={accept}
                className="cookie-banner__agree button button--green"
              >
                Agree & Close
              </button>
              <button onClick={accept} className="cookie-banner__reject">
                <span className="screenreader-text">Reject</span>
                <span className="icon">×</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
