import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { SiteConfig } from '@superrb/gatsby-addons/types'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { links } from '../data/navigation'
import { NavContext } from './../context/nav-context-provider'
import FooterLogo from './icons/footer--logo'
import IconFacebook from './icons/icon--facebook'
import IconInstagram from './icons/icon--instagram'
import IconTwitter from './icons/icon--twitter'
import MenuToggle from './navigation/mobile-menu-toggle'
import scrollToHash from '../utils/scroll-to-hash'

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      config: prismicSiteConfig {
        ...SiteConfig
      }
    }
  `)

  const isMobile = useIsMobile()
  const { navOpen, closeNav, toggleNav } = useContext(NavContext)
  const headerElement = useRef(null)

  const handleScroll = useCallback(() => {
    requestAnimationFrame(() => {
      if (isMobile) {
        if (window.scrollY < 25) {
          document.body.classList.add('at-top')
        } else {
          document.body.classList.remove('at-top')
        }
      }

      if (window.scrollY > 50) {
        document.body.classList.add('header-stuck')
      } else {
        document.body.classList.remove('header-stuck')
      }
    })
  }, [isMobile])

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll, { passive: false })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  /** @type {SiteConfig} config */
  const config = data?.config
  if (!config) {
    return null
  }

  return (
    <header className="main-header" ref={headerElement}>
      <div className="main-header__container">
        {isMobile && (
          <MenuToggle
            className="main-header__toggle"
            label="Open Nav"
            closeLabel="Close Nav"
          />
        )}
        <Link
          to={`/`}
          className="nav__link mobile-logo"
          activeClassName="nav__link--active"
          partiallyActive={true}
          onClick={() => {
            closeNav()
          }}
        >
          <FooterLogo />
        </Link>
        <nav
          className="main-header__nav nav"
          id="nav"
          aria-hidden={isMobile && !navOpen}
        >
          <ul className="nav__list">
            {links.map((link, index) => (
              <>
                {index == 2 && (
                  <li
                    key="logo"
                    className="nav__item nav__item--logo"
                  >
                    <Link
                      to={`/`}
                      className="nav__link"
                      activeClassName="nav__link--active"
                      partiallyActive={true}
                      onClick={() => {
                        closeNav()
                      }}
                    >
                      <FooterLogo />
                    </Link>
                  </li>
                )}
                <li
                  key={index}
                  className="nav__item"
                >
                  <Link to={link.link} className="nav__link" onClick={(event) => {
                    closeNav()

                    const hash = event.currentTarget.hash.slice(1)
                    const element = document.getElementById(hash)

                    if (element) {
                      event.preventDefault()
                      scrollToHash(hash)
                    }
                  }}>
                    <p className="first">{link.title}</p>
                    {!isMobile && (
                      <span className="second" aria-hidden="true">
                        {link.title}
                      </span>
                    )}
                  </Link>
                </li>
              </>
            ))}
            <div className="nav__list--socials">
              <h5>Connect With Us</h5>
              <div className="nav__list--socials-container">
                {config?.data?.facebook_username && (
                  <a href={config?.data?.facebook_username}>
                    <IconFacebook />
                  </a>
                )}
                {config?.data?.instagram_username && (
                  <a href={config?.data?.instagram_username}>
                    <IconInstagram />
                  </a>
                )}
                {config?.data?.twitter_username && (
                  <a href={config?.data?.twitter_username}>
                    <IconTwitter />
                  </a>
                )}
              </div>
            </div>
            <div className="separator"></div>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
