const scrollToHash = (hash) => {
  const element = document.getElementById(hash)

  if (!element) {
    return false
  }

  let top = window.scrollY + element.getBoundingClientRect().top - 85
  window.scrollTo({ top, behavior: 'auto' })
  history.pushState({}, null, `#${hash}`)
}

export default scrollToHash
