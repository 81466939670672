import { Seo } from '@superrb/gatsby-addons/components'
import { Metadata } from '@superrb/gatsby-addons/types'
import { graphql } from 'gatsby'
import React from 'react'
import ContactDetails from '../components/contact-page/contact-details'
import ContactForm from '../components/contact-page/contact-form'
import Hero from '../components/hero'
import { Hero as HeroType } from '../types/components/hero'
import {
  ContactDetailsSection,
  ContactFormSection,
  ContactPage,
} from '../types/pages/contact-page'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const ContactUs = ({ data }) => {
  const page: ContactPage = data.page

  if (!page) {
    return null
  }

  return (
    <>
      <Seo data={page.data as Metadata} />

      <Hero data={page.data as HeroType} />

      <div className="contact-page">
        <ContactDetails data={page.data as ContactDetailsSection} />
        <ContactForm data={page.data as ContactFormSection} />
      </div>
    </>
  )
}

export const query = graphql`
  query ContactPageQuery {
    page: prismicContactPage {
      ...ContactPage
    }
  }
`

export default withPrismicPreview(ContactUs)
