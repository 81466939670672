import { Seo } from '@superrb/gatsby-addons/components'
import { Metadata } from '@superrb/gatsby-addons/types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import React, {useState, useEffect} from 'react'
import Awards from '../components/homepage/awards'
import FarmToFork from '../components/homepage/farm-to-fork'
import Farms from '../components/homepage/farms'
import FeaturedProducts from '../components/homepage/featured-products'
import Hero from '../components/hero'
import Instagram from '../components/homepage/instagram'
import TickerTape from '../components/homepage/ticker-tape'
import Video from '../components/homepage/video'
import WhereToBuy from '../components/homepage/where-to-buy'
import Separator from '../components/separator'
import Homepage, {
  AwardsSection,
  FarmsSection,
  FarmToForkSection,
  FeaturedProductsSection,
  HomepageHero,
  TickerTapeSection,
  VideoSection,
  WhereToBuySection,
} from '../types/pages/homepage'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Cookies from 'js-cookie'

const Index = ({ data }) => {
  const page: Homepage = data.page

  if (!page) {
    return null
  }

  const {
    data: { page_title },
  } = page

  const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(false)

  useEffect(() => {
    const checker = window.setInterval(() => {
      const checked = Cookies.get('gatsby-accepted-cookies') || false
      setCookiesAccepted(!!checked)

      if (!!checked) {
        window.clearInterval(checker)
      }
    }, 1000)

  }, [setCookiesAccepted])

  return (
    <>
      <Seo data={page.data as Metadata} />
      <Hero
        data={
          {
            ...page.data,
            hero_button_link: { url: '#featured-products' },
          } as HomepageHero
        }
      />
      <FarmToFork data={page.data as FarmToForkSection} />
      <FeaturedProducts data={page.data as FeaturedProductsSection} />
      <TickerTape data={page.data as TickerTapeSection} />
      <Separator />
      <Farms data={page.data as FarmsSection} />
      {cookiesAccepted && (
        <Video data={page.data as VideoSection} />
      )}
      <Awards data={page.data as AwardsSection} />
      <WhereToBuy data={page.data as WhereToBuySection} />
    </>
  )
}

export const query = graphql`
  query HomepageQuery {
    page: prismicHomepage {
      ...Homepage
    }
  }
`

export default withPrismicPreview(Index)
